import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";
export interface Permission {
  module: string;
  name: string;
  identifier: string;
  sortOrder: number;
  isMenu: boolean;
  notes: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
  isChecked?: boolean;
}
export interface PermissionMenuItem {
  module: string;
  name: string;
  identifier: string;
  sortOrder: number;
  isMenu: boolean;
  route: string;
  icon: string;
  childMenus: PermissionMenuItem[];
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  createdBy: string;
  parentPermissionId?: string; // Optional, as not all child menus have this field
}

export interface PermissionMenu {
  module: string;
  name: string;
  identifier: string;
  sortOrder: number;
  isMenu: boolean;
  route: string;
  icon: string;
  childMenus: PermissionMenuItem[];
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  createdBy: string;
  isChecked?: boolean;
}

export const rolesAndPermissionsApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    rolesList: builder.mutation({
      query: (data) => ({
        url: `/role/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    permissionsList: builder.mutation({
      query: (data) => ({
        url: `/permission/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createRolePermission: builder.mutation({
      query: (data) => ({
        url: "/rolepermission/create",
        method: "POST",
        body: data,
      }),
    }),
    createRole: builder.mutation({
      query: (data) => ({
        url: "/role/create",
        method: "POST",
        body: data,
      }),
    }),
    editRole: builder.mutation({
      query: (data) => ({
        url: "/role/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getRolesPermission: builder.mutation({
      query: (data) => ({
        url: `/rolepermission/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getRolesById: builder.mutation({
      query: (data) => ({
        url: `role/getbyid?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    deleteRolesPermission: builder.mutation({
      query: (data) => ({
        url: `rolepermission/delete?${getFilters(data)}`,
        method: "POST",
      }),
    }),
     duplicateRolesPermission: builder.mutation({
      query: (data) => ({
        url: `/role/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
  }),
});
export const {
  useRolesListMutation,
  usePermissionsListMutation,
  useCreateRolePermissionMutation,
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetRolesPermissionMutation,
  useGetRolesByIdMutation,
  useDeleteRolesPermissionMutation,
  useDuplicateRolesPermissionMutation
} = rolesAndPermissionsApiSlice;
