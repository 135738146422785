import { useEffect, useState } from "react";
import "./userprofile.scss";
import { ReactSVG } from "react-svg";
import editicon from "../../../assets/images/edit-icon.svg";
import resetpasswordicon from "../../../assets/images/reset-password-icon.svg";
import Image from "react-bootstrap/Image";
import profilepic from "../../../assets/images/userProfile.png";
import CustomModal from "../../../components/customModal/CustomModal";
import { Button, Form, FormText } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toggleClass } from "../../../utils/commonUtils";
import useUser from "../../../Hooks/useUser";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import Loading from "../../../components/LoadingPage/Loading";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import useAuth from "../../../Hooks/useAuth";
const LoginUserProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getUserByIdApi, getUserByIdLoading } = useUser();
  const { changePasswordApi, changePasswordLoading, logout } = useAuth();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const toggleModal = () => {
    resetForm();
    toggleClass();
    setShowModal(!showModal);
  };
  useEffect(() => {
    // const blob = new Blob([byteArray]);
    // const url = URL.createObjectURL(blob);
    // setImageUrl(url);
    getUserByIdApi({ id: localStorage.getItem("id") }).then((res) => {
      setUserData(res);
    });
  }, []);

  const { handleChange, values, handleSubmit, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        id: currentUserId,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: Yup.object({
        currentPassword: Yup.string().required("Old Password is Required"),
        newPassword: Yup.string().required("New Password is Required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword")], "Passwords must match")
          .required("Confirm Password is Required"),
      }),

      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
          changePasswordApi(values).then((res) => {
            if (res) {
              toggleModal();
              logout();
            }
          });
        } catch (error) {}
        setSubmitting(false);
      },
    });

  return (
    <div className="login-user-profile-wrapper">
      {getUserByIdLoading || changePasswordLoading ? <Loading /> : null}
      <div className="login-user-profile-header-wrapper">
        <h2>My Profile</h2>
        <div className="login-user-header-box">
          <div className="login-user-box">
            <div className="login-user-img">
              <Image  src={`${localStorage.getItem("profilePicture") ?? profilepic}`} roundedCircle />
            </div>
            <div className="login-user-title">
              <h4>
                {localStorage.getItem("firstName") +
                  " " +
                  localStorage.getItem("lastName")}
              </h4>
              <span>{userData?.role?.roleName ?? "-"}</span>
            </div>
          </div>
          <div className="login-user-action-box">
            <span className="login-user-action-btn edit-btn">
              <Link to="/edit-profile-user">
                <ReactSVG className="svg-box" wrapper="span" src={editicon} />{" "}
                Edit
              </Link>
            </span>
            <span
              className="login-user-action-btn change-password-btn"
              onClick={toggleModal}
            >
              <ReactSVG
                className="svg-box"
                wrapper="span"
                src={resetpasswordicon}
              />
              Change Password
            </span>
          </div>
        </div>
      </div>
      <div className="login-user-profile-info">
        <div className="profile-detail-box">
          <div className="profile-info-box full-width">
            <h5>General Information</h5>
          </div>
          <div className="profile-info-box">
            <label>User Name</label>
            <p>{userData?.userName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>First Name</label>
            <p>{userData?.fName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Middle Name</label>
            <p>{userData?.mi ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Last Name</label>
            <p>{userData?.lName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Email</label>
            <p>{userData?.email ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Phone Number</label>
            <p>{userData?.phone ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Active</label>
            <p>{userData?.isActive ? "Yes" : "No"}</p>
          </div>
          <div className="profile-info-box full-width">
            <h5 style={{ marginTop: "10px" }}>Other Information</h5>
          </div>
          <div className="profile-info-box">
            <label>Clinical Role</label>
            <p>{userData?.role?.roleName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Access Type</label>
            <p>{userData?.clinicalRole?.roleName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Location</label>
            <p>NA</p>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          id="change-password-modal"
          title="Change Password"
          content={
            <>
              <div className="cost-calc-box">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      type={showCurrentPassword ? "text" : "password"}
                      placeholder="Enter Old Password"
                      name="currentPassword"
                      id="currentPassword"
                      onChange={handleChange}
                      value={values.currentPassword}
                      isInvalid={
                        touched.currentPassword && !!errors.currentPassword
                      }
                    />
                    {touched.currentPassword && errors.currentPassword ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.currentPassword}
                      </Form.Control.Feedback>
                    ) : null}
                    {showCurrentPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => {
                          setShowCurrentPassword(!showCurrentPassword);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => {
                          setShowCurrentPassword(!showCurrentPassword);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      name="newPassword"
                      id="newPassword"
                      onChange={handleChange}
                      value={values.newPassword}
                      isInvalid={touched.newPassword && !!errors.newPassword}
                    />

                    {touched.newPassword && errors.newPassword ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    ) : null}

                    {showPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>Re-Enter New Password</Form.Label>
                    <Form.Control
                      type={showVerifyPassword ? "text" : "password"}
                      placeholder="Re-Enter New Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={handleChange}
                      value={values.confirmPassword}
                      isInvalid={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                    />
                    {touched.confirmPassword && errors.confirmPassword ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    ) : null}
                    {showVerifyPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => {
                          setShowVerifyPassword(!showVerifyPassword);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => {
                          setShowVerifyPassword(!showVerifyPassword);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <FormText>
                      Please note that you will be logged out of the system on
                      changing the password
                    </FormText>
                  </Form.Group>
                  <div className="full-width text-end">
                    <Button
                      style={{ marginRight: "10px" }}
                      variant="outline-primary"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                  </div>
                </Form>
              </div>
            </>
          }
          onClose={toggleModal}
        />
      )}
    </div>
  );
};

export default LoginUserProfile;
