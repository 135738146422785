import "./login.scss";
import loginlogo from "../../assets/images/login-logo.svg";
import awsLogo from "../../assets/images/Powered-by-AWS.png";
import loginimage from "../../assets/images/login-image.jpg";
import Form from "react-bootstrap/Form";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, FormikHelpers } from "formik";
import type { Credentials } from "../../redux/auth/authApiSlice";
import useAuth from "../../Hooks/useAuth";
import { Link } from "react-router-dom";
import useAdmin from "../../Hooks/useAdmin";
import { setMenuItems, setPermissions } from "../../redux/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";
import useRolesAndPermissions from "../../Hooks/useRolesAndPermissions";

const Login = () => {
  const { loginAction, loginLoading } = useAuth();
  const [isLoginError, setIsLoginError] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const { getPermissionsAPI } = useAdmin();
  const [defaultClientId, setDefaultClientId] = useState(
    localStorage.getItem("clientId") || "",
  );
  const [defaultUserId, setDefaultUserId] = useState(
    localStorage.getItem("username") || "",
  );
  const dispatch = useAppDispatch();
  const { getRolesPermissionAPI } = useRolesAndPermissions();
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      username: defaultUserId,
      password: "",
      clientid: defaultClientId,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        // .min(5, "Must be 5 characters or more")
        // .max(30, "Must be 30 characters or less")
        .required("Email / Username is Required"),
      password: Yup.string()
        // .min(1, "Must be 1 characters or more")
        // .max(30, "Must be 30 characters or less")
        .required("Password is Required"),
      clientid: Yup.string().required("Client ID is Required"),
    }),

    onSubmit: async (
      values: Credentials,
      { setSubmitting }: FormikHelpers<Credentials>,
    ) => {
      try {
        setClientId();
        await loginAction(values).then((res) => {
          getMenuItems(res.role.id);
          getPermissions(res.role.id);
          localStorage.setItem("profilePicture",res?.profilePicture);
        });
        localStorage.setItem("profileClientId", values.clientid);
      } catch (err: any) {
        setIsLoginError(true);
        setLoginError(err.message);
      }
      setSubmitting(false);
    },
  });
  useEffect(() => {
    setIsRememberMe(!!defaultClientId && !!defaultUserId);
  }, []);
  const getMenuItems = (id: string) => {
    getPermissionsAPI(id).then((res: any) => {
      dispatch(setMenuItems(res));
    });
  };
  const getPermissions = (id: string) => {
    getRolesPermissionAPI({ roleId:id }).then((data: any) => {
      const groupedData = data
        .map((item: { permission: any }) => item.permission)
        .filter(
          (x: { isMenu: boolean; isActive: boolean }) =>
            x.isMenu === false && x.isActive === true,
        );
      dispatch(setPermissions(groupedData));
    });
  };
  const setClientId = () => {
    if (isRememberMe) {
      setDefaultUserId(values.username);
      setDefaultClientId(values.clientid);
      localStorage.setItem("clientId", values.clientid);
      localStorage.setItem("username", values.username);
    } else {
      localStorage.setItem("clientId", "");
      localStorage.setItem("username", "");
      setDefaultClientId("");
      setDefaultUserId("");
    }
  };
  const convertToUperCase = (e: any) => {
    handleChange("clientid")(e.currentTarget.value.toUpperCase());
  };
  return (
    <div className="login-wrapper">
      <Container fluid>
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={12} md={6}>
            <div className="login-content-wrapper">
              <div className="login-content">
                <img src={loginlogo} alt="logo" />
                <h4>Sign in</h4>
                <p>Welcome back! Please sign in to continue.</p>
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-4">
                    <Form.Label>Client ID</Form.Label>
                    <Form.Control
                      id="clientid"
                      name="clientid"
                      type="text"
                      placeholder="Enter Client ID"
                      onChange={(e) => {
                        convertToUperCase(e);
                        setLoginError(null);
                      }}
                      value={values.clientid.toUpperCase()}
                      isInvalid={touched.clientid && !!errors.clientid}
                    />

                    {touched.clientid && errors.clientid ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.clientid}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Email / Username</Form.Label>
                    <Form.Control
                      type="username"
                      placeholder="Enter Email / Username"
                      required
                      id="username"
                      name="username"
                      onChange={(e) => {
                        handleChange(e);
                        setLoginError(null);
                      }}
                      value={values.username}
                      isInvalid={touched.username && !!errors.username}
                    />
                    {touched.username && errors.username ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={(e) => {
                        handleChange(e);
                        setLoginError(null);
                      }}
                      value={values.password}
                      isInvalid={touched.password && !!errors.password}
                    />
                    {(!isLoginError && !errors.password) ||
                    (isLoginError && !errors.password) ? (
                      showPassword ? (
                        <i
                          className="bi bi-eye-fill"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      ) : (
                        <i
                          className="bi bi-eye-slash-fill"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )
                    ) : null}

                    {touched.password && errors.password ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    ) : null}
                    {isLoginError ? (
                      <span className="login-error"> {loginError}</span>
                    ) : null}
                  </Form.Group>

                  <Form.Group
                    className="mb-4 forgot-pass-link-box"
                    style={{ textAlign: "right" }}
                  >
                    <span className="remember-me-check">
                      <Form.Check
                        inline
                        type="checkbox"
                        id="remember-me"
                        checked={isRememberMe}
                        onChange={() => setIsRememberMe(!isRememberMe)}
                      />
                      Remember Me
                    </span>
                    <Button variant="link" href="/forgot-password">
                      Forgot Password?
                    </Button>
                  </Form.Group>
                  <Form.Group className="mb-2 d-grid">
                    <Button
                      disabled={loginLoading}
                      type="submit"
                      variant="primary"
                      onClick={handleSubmit as any}
                    >
                      Sign In
                    </Button>
                  </Form.Group>
                  {/* <Form.Group className="mb-4 d-grid">
                    <Button variant="outline-primary">Sign in with SSO</Button>
                  </Form.Group>
                  <Form.Group className="text-center">
                    <p>
                      Don't have an account?{" "}
                      <Button variant="link" href="#">
                        Sign Up
                      </Button>
                    </p>
                  </Form.Group> */}
                </Form>
              </div>
              <img src={awsLogo} alt="aws" />
              <p className="copy-text">
                Copyright © 2024 TriNet Medical, LLC. All rights reserved.
              </p>
              <p style={{fontSize:"12px"}}>Last Version: 2024-11-26</p>
            </div>
          </Col>
          <Col
            style={{
              backgroundImage: `url(${loginimage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
            xs={12}
            md={6}
          >
            <div className="login-image-wrapper">
              <h4>Company</h4>
              <h1>Overview</h1>
              <p>
                TriNet Medical provides Healthcare IT solutions with
                quantifiable results in the areas of Data Management services,
                Technology Services and Barcode Integration with EMR.
              </p>
              <p>
                We deliver highly valuable and innovative solutions that bridge
                our client's execution gaps through a clear understanding of
                their business needs and a culture of shared commitments.
              </p>
              <div className="url-link-box">
                <Link
                  className="text-primary-700"
                  to="https://www.trinetmedical.com/"
                  target="_blank"
                >
                  www.trinetmedical.com
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
