import { getErrorMessage } from "../utils/commonUtils";
import { toast } from "react-toastify";
import {
  useGetLotSearchItemsMutation,
  useGetLotSearchPatientsMutation,
  useGetUserslistMutation,
  useInventoryAdjustmentDetailExportMutation,
  useInventoryExpireExportMutation,
  useInventoryRecalledItemExportMutation,
  useInventoryReceiveDetailExportMutation,
  useInventoryReconciliationExportMutation,
  useInventoryStausExportMutation,
  useInventoryTranferDetailExportMutation,
  useInventoryUsageDetailExportMutation,
  useLotSearchItemExportMutation,
  useLotSearchPatientsExportMutation,
  useMedicationListExportMutation,
  useProviderListExportMutation,
  useTrReportsDDMutation,
  useTrReportsMutation,
  useUserListExportMutation,
} from "../redux/reports/reportsApiSlice";

export default function useReports() {
  const [getUserslist, { isLoading: getUserslistLoading }] =
    useGetUserslistMutation();
  const [trReportsDD, { isLoading: trReportsDDLoading }] =
    useTrReportsDDMutation();
  const [trReports, { isLoading: trReportsLoading }] = useTrReportsMutation();
  const [inventoryStausExport, {isLoading: inventoryStausExportLoading}] = useInventoryStausExportMutation();
  const [getLotSearchItems,{isLoading:getLotSearchItemsLoading}] = useGetLotSearchItemsMutation();
  const [lotSearchItemExport, {isLoading:lotSearchItemExportLoading}] = useLotSearchItemExportMutation();
  const [getLotSearchPatients,{isLoading:getLotSearchPatientsLoading}] = useGetLotSearchPatientsMutation(); 
  const [lotSearchPatientsExport,{isLoading:lotSearchPatientsExportLoading}] = useLotSearchPatientsExportMutation();
  const [inventoryExpireExport,{isLoading:inventoryExpireExportLoading}] =useInventoryExpireExportMutation();
  const [inventoryReceiveDetailExport,{isLoading:inventoryRecevieDetailExportLoading}] = useInventoryReceiveDetailExportMutation();
  const [inventoryUsageDetailExport, {isLoading:inventoryUsageDetailExportLoading}] = useInventoryUsageDetailExportMutation();
  const [inventoryTranferDetailExport,{isLoading:inventoryTranferDetailExportLoading}] = useInventoryTranferDetailExportMutation();
  const [inventoryAdjustmentDetailExport,{isLoading:inventoryAdjustmentDetailExportLoading}] = useInventoryAdjustmentDetailExportMutation();
  const [inventoryReconciliationExport,{isLoading:inventoryReconciliationExportLoading}]= useInventoryReconciliationExportMutation();
  const [inventoryRecalledItemExport,{isLoading:inventoryRecalledItemExportLoading}]= useInventoryRecalledItemExportMutation();
  const [providerListExport,{isLoading: providerListExportLoading}] = useProviderListExportMutation();
  const [userListExport,{isLoading:userListExportLoading}] = useUserListExportMutation();
  const [medicationListExport,{isLoading:medicationListExportLoading}] = useMedicationListExportMutation();

  const getUserslistAPI = async (data: any) => {
    try {
      const response: any = await getUserslist(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const trReportsDDAPI = async () => {
    try {
      const response: any = await trReportsDD({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const trReportsAPI = async (data: any) => {
    try {
      const response: any = await trReports(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryStausExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryStausExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryExpireExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryExpireExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryReceiveExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryReceiveDetailExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryUsageDetailExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryUsageDetailExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryTranferDetailExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryTranferDetailExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryAdjustmentDetailExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryAdjustmentDetailExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryReconciliationExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryReconciliationExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const inventoryRecallItemExportAPI = async (data: any) => {
    try{
      const response: any = await inventoryRecalledItemExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const providerListExportAPI = async (data: any) => {
    try{
      const response: any = await providerListExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const userListExportAPI = async (data: any) => {
    try{
      const response: any = await userListExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const medicationListExportAPI = async (data: any) => {
    try{
      const response: any = await medicationListExport(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getLotSearchItemsAPI = async (data: any) => {
    try{
      const response: any = await getLotSearchItems(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const lotSearchItemExportAPI = async (data: any) => {
    try{
      const response: any = await lotSearchItemExport(data).unwrap();
      toast.success("Success!. File downloaded successfully");
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
   const getLotSearchPatientsAPI = async (data: any) => {
    try{
      const response: any = await getLotSearchPatients(data).unwrap();
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const lotSearchPatientsExportAPI = async (data: any) => {
    try{
      const response: any = await lotSearchPatientsExport(data).unwrap();
      toast.success("Success!. File downloaded successfully");
      return response;
    } catch (err: any){
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  return {
    getUserslistAPI,
    getUserslistLoading,
    trReportsDDAPI,
    trReportsDDLoading,
    trReportsAPI,
    trReportsLoading,
    inventoryStausExportAPI,
    inventoryStausExportLoading,
    getLotSearchItemsAPI,
    getLotSearchItemsLoading,
    lotSearchItemExportAPI,
    lotSearchItemExportLoading,
    getLotSearchPatientsAPI,
    getLotSearchPatientsLoading,
    lotSearchPatientsExportAPI,
    lotSearchPatientsExportLoading,
    inventoryExpireExportAPI,
    inventoryExpireExportLoading,
    inventoryReceiveExportAPI,
    inventoryRecevieDetailExportLoading,
    inventoryUsageDetailExportAPI,
    inventoryUsageDetailExportLoading,
    inventoryTranferDetailExportAPI,
    inventoryTranferDetailExportLoading,
    inventoryAdjustmentDetailExportAPI,
    inventoryAdjustmentDetailExportLoading,
    inventoryReconciliationExportAPI,
    inventoryReconciliationExportLoading,
    inventoryRecallItemExportAPI,
    inventoryRecalledItemExportLoading,
    providerListExportAPI,
    providerListExportLoading,
    userListExportAPI,
    userListExportLoading,
    medicationListExportAPI,
    medicationListExportLoading
  };
}
