import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  Credentials,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useVerifyResetPassMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGuideUrlApiSliceMutation,
} from "./../redux/auth/authApiSlice";
import { commonBaseApiSlice } from "../redux/commonBaseApiSlice";
import {
  resetAuthState,
  selectCurrentAuthState,
  selectCurrentLoginStatus,
  selectCurrentRefreshToken,
  selectedRoleId,
  setCredentials,
  setMenuItems,
  setPermissions,
} from "./../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "./../redux/hooks";
import { getErrorMessage } from "../utils/commonUtils";
/**
 * Custom hook for handling authentication-related functionality.
 * Manages login, logout, and provides necessary data and loading states.
 */
export default function useAuth() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [loginApi, { isLoading: loginLoading }] = useLoginMutation();
  const [logoutApi, { isLoading: logoutLoading }] = useLogoutMutation();

  const [forgotPassword, { isLoading: forgotPasswordLoading }] =
    useForgotPasswordMutation();
  const [verifyResetPass, { isLoading: verifyResetPassLoading }] =
    useVerifyResetPassMutation();
  const [resetPassword, { isLoading: resetPasswordLoading }] =
    useResetPasswordMutation();
  const [changePassword, { isLoading: changePasswordLoading }] =
    useChangePasswordMutation();
  const [guideApiSlice, { isLoading: guideApiSliceLoading }] =
    useGuideUrlApiSliceMutation();
  const refreshToken = useAppSelector(selectCurrentRefreshToken);
  const isLoggedIn = useAppSelector(selectCurrentLoginStatus);
  const authData = useAppSelector(selectCurrentAuthState);
  const role = useAppSelector(selectedRoleId);

  const guideApiSliceApi = async (values: any) => {
    try {
      const response: any = await guideApiSlice(values).unwrap();
      return response;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const forgotPasswordApi = async (values: any) => {
    try {
      const response: any = await forgotPassword(values).unwrap();
      toast.success("Email sent Successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const verifyResetPassApi = async (values: any) => {
    try {
      const response: any = await verifyResetPass(values).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const resetPasswordApi = async (values: Credentials) => {
    try {
      const response: any = await resetPassword(values).unwrap();
      toast.success("Password Updated Successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const changePasswordApi = async (values: Credentials) => {
    try {
      const response: any = await changePassword(values).unwrap();
      toast.success("Password Updated Successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  /**
   * Performs login with the provided login form values.
   * @param values - credentials.
   */
  const loginAction = async (values: Credentials) => {
    try {
      const response: any = await loginApi(values).unwrap();
      dispatch(setCredentials(response.result));
      navigate("/");
      toast.success("Login Successful.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  /**
   * Performs logout by making a request to the logout API endpoint and clearing storages.
   */
  const logout = async () => {
    try {
      logoutApi(refreshToken).unwrap();
      dispatch(commonBaseApiSlice.util.resetApiState());
      dispatch(resetAuthState());
      dispatch(setMenuItems([]));
      dispatch(setPermissions([]));
      localStorage.clear();
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  return {
    isLoggedIn,
    loginAction,
    authData,
    logout,
    loginLoading,
    logoutLoading,
    role,
    forgotPasswordApi,
    verifyResetPassApi,
    resetPasswordApi,
    forgotPasswordLoading,
    verifyResetPassLoading,
    resetPasswordLoading,
    changePasswordApi,
    changePasswordLoading,
    guideApiSliceApi,
    guideApiSliceLoading,
  };
}
