import Form from "react-bootstrap/Form";
import "./adduserprofile.scss";
import { Button, Image } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import InputGroup from "react-bootstrap/InputGroup";
import profilepic from "../../../assets/images/userProfile.png";
import editicon from "../../../assets/images/edit-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import deleteitemmodalimge from "../../../assets/images/delete-item-modal-image.svg";
import uploadfileicon from "../../../assets/images/upload-file-icon.svg";
import { useEffect, useState } from "react";
import CustomModal from "../../../components/customModal/CustomModal";
import { ALLOWED_IMAGE_FILE_TYPES, MAX_IMAGE_FILE_SIZE, toggleClass } from "../../../utils/commonUtils";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useUser from "../../../Hooks/useUser";
import Loading from "../../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";

const AddUserProfile = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadImgModal, setShowUploadImgModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const navigate = useNavigate();
  const { updateUsers, editUserLoading, getUserByIdApi, getUserByIdLoading, uploadProfilePictureApi, uploadProfilePictureLoading } =
    useUser();
  const currentUserId = localStorage.getItem("id");
  const [fileData, setFileData] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [dynamicUserVal, setDynamicUserVal] = useState({
    fName: "",
    lName: "",
    userName: "",
    mi: "",
    email: "",
    clinicalRoleId: "",
    roleId: "",
    phone: "",
    deaNumber: "",
    profilePicture: "",
    isActive: true,
  });
  const getUserDetails = ()=>{
    getUserByIdApi({ id: currentUserId }).then((res) => {
      setUserData(res);
      setDynamicUserVal((prevState) => {
        return {
          ...prevState,
          userName: res.userName,
          fName: res.fName,
          lName: res.lName,
          mi: res.mi,
          email: res.email,
          clinicalRoleId: res.clinicalRoleId,
          roleId: res.roleId,
          phone: res.phone,
          deaNumber: res.deaNumber,
          profilePicture: res.profilePicture,
          isActive: res.isActive,
        };
      });
    });
  }

  useEffect(() => {
    getUserDetails();
  }, []);
  const toggleDeleteModal = () => {
    toggleClass();
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleUploadImgModal = () => {
    toggleClass();
    setShowUploadImgModal(!showUploadImgModal);
  };
  const {
    handleChange,
    values,
    handleSubmit,
    touched,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...dynamicUserVal,
    },
    validationSchema: Yup.object({
      fName: Yup.string()
        .max(40, "First Name can not be more than 40 characters")
        .required("First Name is Required"),
      lName: Yup.string()
        .max(40, "Last Name can not be more than 40 characters")
        .required("Last Name is Required"),
      mi: Yup.string()
        .notRequired(),
      phone: Yup.string()
        .max(30, "Phone Number should not be more than 30 characters")
        .notRequired(),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      const valid = _.isEqual(values, dynamicUserVal);
      if (!valid) {
           try {
          updateUsers({ ...values, id: currentUserId }).then((res) => {
            localStorage.setItem("firstName", res.fName);
            localStorage.setItem("lastName", res.lName);
            navigate("/user-profile");
            getUserDetails();
          });
        } catch (err) {
          toast.error("Profile Update Unsuccesfully!");
        }
      }
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try{
      if(event.target.files){
        const selectedFile:any = event.target.files[0];
        if(!selectedFile){
          toast.error("Please upload a image.");
          setFileData([]);
          return;
        }
      if(!ALLOWED_IMAGE_FILE_TYPES.includes(selectedFile.type)){
        toast.error("Invalid file type. Only JPEG & PNG are allowed.");
        setFileData([]);
        return;
      }
      if (selectedFile.size > MAX_IMAGE_FILE_SIZE) {
        toast.error(`File is too large. Maximum allowed size is ${MAX_IMAGE_FILE_SIZE / 1024} KB.`);
        setFileData([]);// Clear the file
        return;
      }
       // If all validations pass, process the file
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const result = event.target?.result;
        if (typeof result === "string") {
          const base64String = result;
          // Update the state with the selected file and base64 URL
          setFileData(selectedFile);
          setImageUrl(base64String);
        }
      };

      // Start reading the file as a Data URL
      reader.readAsDataURL(selectedFile);
    }
  }catch(e:any){
      toast.error(e.message);
    }
  };
  const clearImg = () => {
    setFileData(null);
    setImageUrl(null);
  };
  const handleUpload = () => {
    if (fileData) {
      try{
        const formDataConv:any = new FormData();
        formDataConv.append('file', fileData);
        uploadProfilePictureApi(formDataConv).then((res) => {
            if(res){
              toast.success(res?.message);
              clearImg();
              localStorage.setItem("profilePicture",res?.result?.profilePicture);
              navigate("/user-profile");
              getUserDetails();
            }
          });
      }catch(e:any){
        toast.error("Profile Picture Upload Unsuccesfully!");
      }
    }
  };
  const deleteUserIcon = () => {
    updateUsers({ ...values, id: currentUserId, profilePicture: null }).then(
      toggleDeleteModal,
    );
  };
  return (
    <div className="add-user-profile-wrapper">
      {getUserByIdLoading || editUserLoading || uploadProfilePictureLoading ? <Loading /> : null}
      <div className="login-user-profile-header-wrapper">
        <h2>Edit Profile</h2>
        <div className="login-user-header-box">
          <div className="login-user-box">
            <div className="login-user-img dropdown" data-bs-toggle="dropdown">
              <Image src={`${localStorage.getItem("profilePicture") ?? profilepic}`} roundedCircle />
              <div className="edit-img-overlay">
                <span className="img-edit-icon">
                  <ReactSVG className="svg-box" wrapper="span" src={editicon} />
                </span>
              </div>
              <ul className="dropdown-menu">
                <li onClick={toggleUploadImgModal}>
                  <ReactSVG className="svg-box" wrapper="span" src={editicon} />{" "}
                  Change Profile Photo
                </li>
                <li onClick={toggleDeleteModal}>
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={deleteicon}
                  />
                  Delete
                </li>
              </ul>
            </div>
            <div className="login-user-title">
              <h4>
                {localStorage.getItem("firstName") +
                  " " +
                  localStorage.getItem("lastName")}
              </h4>
              <span>{userData?.role?.roleName ?? "-"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="add-user-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 full-width">
            <h5>General Information</h5>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              value={userData?.userName ?? "-"}
              type="text"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              id="fName"
              name="fName"
              value={values.fName}
              onChange={(e) => setFieldValue("fName", e.target.value)}
              onBlur={(e) => setFieldValue("fName", e.target.value)}
              isInvalid={touched.fName && !!errors.fName}
            />
            {touched.fName && errors.fName ? (
              <Form.Control.Feedback type="invalid">
                {errors.fName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Middle Initials</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Middle Initials"
              id="mi"
              name="mi"
              onChange={(e) => handleChange("mi")(e.target.value)}
              value={values.mi}
              isInvalid={touched.mi && !!errors.mi}
            />
            {touched.mi && errors.mi ? (
              <Form.Control.Feedback type="invalid">
                {errors.mi}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              id="lName"
              name="lName"
              onChange={(e) => setFieldValue("lName", e.target.value)}
              value={values.lName}
              isInvalid={touched.lName && !!errors.lName}
            />
            {touched.lName && errors.lName ? (
              <Form.Control.Feedback type="invalid">
                {errors.lName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email Address"
              value={userData?.email ?? "-"}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Phone Number</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                id="phone"
                name="phone"
                onChange={(e) => handleChange("phone")(e.target.value)}
                value={values.phone}
                isInvalid={touched.phone && !!errors.phone}
              />
              {touched.phone && errors.phone ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="item-checkbox-content">
              <Form.Check
                inline
                label="Active"
                name="active"
                type="checkbox"
                checked={!!userData?.isActive}
                disabled={true}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3 full-width">
            <h5>Other Information</h5>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Clinical Role</Form.Label>
            <Form.Control
              value={userData?.role?.roleName ?? "-"}
              type="text"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Access Type</Form.Label>
            <Form.Control
              disabled={true}
              value={userData?.clinicalRole?.roleName ?? "-"}
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Location"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => resetForm}
            >
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Form.Group>
        </Form>
      </div>

      {showDeleteModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={deleteitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  delete your avatar?
                </h5>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleDeleteModal}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={deleteUserIcon}
                >
                  Delete
                </Button>
              </div>
            </>
          }
          onClose={toggleDeleteModal}
        />
      )}
      {showUploadImgModal && (
        <CustomModal
          id="upload-avatar-modal"
          title="Change Profile Photo"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="img-upload-box">
                  <Form.Control
                    type="file"
                    id="avatar-upload-input"
                    accept=".jpeg, .png"
                    onChange={handleFileChange}
                  />
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      className="img-preview"
                      style={{ width: "200px", height: "200px" }}
                    />
                  ) : (
                    <>
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={uploadfileicon}
                      />
                      <p>Drag and Drop your image here or Click to Upload</p>
                    </>
                  )}
                </div>
                <div className="file-type-hint">
                  <span>File Type: JPEG, PNG</span>
                  <span
                    style={fileData && fileData?.size > MAX_IMAGE_FILE_SIZE ? { color: "red" } : {}}
                  >
                    Maximum Size: 500 KB
                  </span>
                </div>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() => {
                    clearImg();
                    toggleUploadImgModal();
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={handleUpload} disabled={fileData === null}>
                  Upload
                </Button>
              </div>
            </>
          }
          onClose={toggleUploadImgModal}
        />
      )}
    </div>
  );
};

export default AddUserProfile;
