import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "../commonBaseApiSlice";

export const settingApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.mutation({
      query: (data) => ({
        url: `/adminsetting/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createSettings: builder.mutation({
      query: (data) => ({
        url: "/adminsetting/create",
        method: "POST",
        body: data,
      }),
    }),
    editSettings: builder.mutation({
      query: (data) => ({
        url: "/adminsetting/edit",
        method: "PUT",
        body: data,
      }),
    }),
    getSettingsById: builder.mutation({
      query: (id) => ({
        url: `/adminsetting/getbyid?id=${id}`,
        method: "GET",
      }),
    }),
    deleteSetting: builder.mutation({
      query: (data) => ({
        url: `/adminsetting/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    uploadCompanyLogo: builder.mutation({
      query: (data) => ({
        url: "/adminsetting/uploadreportlogo",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetSettingsMutation,
  useCreateSettingsMutation,
  useEditSettingsMutation,
  useDeleteSettingMutation,
  useGetSettingsByIdMutation,
  useUploadCompanyLogoMutation
} = settingApiSlice;
