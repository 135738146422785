import { useState } from "react";
import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Button, Table } from "react-bootstrap";
import Loading from "../../../components/LoadingPage/Loading";
import useReports from "../../../Hooks/useReports";
import { toast } from "react-toastify";
import moment from "moment";

const LotSearchPatients = () => {
  const [ndc, setNdc] = useState("");
  const [lot, setLot] = useState("");
  const [lotPatientsData, setLotPatientsData] = useState<any>({});
  const { getLotSearchPatientsAPI, getLotSearchPatientsLoading, lotSearchPatientsExportAPI, lotSearchPatientsExportLoading} = useReports();
  const onLotChange = (e: any) => {
    setLot(e.target.value);
  };
  const onNDCChange = (e: any) => {
    setNdc(e.target.value);
  };
   const getlotPatientsData = ()=>{
        getLotSearchPatientsAPI({
          ndcCode: ndc,
          lotNo: lot
      }).then((res) => {
          if(res?.result.length <= 0 && res?.status === 400){
             toast.error(res?.message);
             return;
          }else{
            setLotPatientsData(res?.result[0]);
          }
      });
    }
  const cleearAll = () => {
      setLot("");
      setNdc("");
      setLotPatientsData({});
  };

  const handleExport = async ()=>{
    const response = await lotSearchPatientsExportAPI({
      ndcCode: ndc,
      lotNo: lot
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Lot_Search_Patients_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="Lot Search Results (Patients)"
        reportDate={lotPatientsData?.subTitleReport ?? ""}
        srxImage={lotPatientsData?.srxImage ?? ""}
        companyImage={lotPatientsData?.clientImage ?? ""}
      />
      <div className="report-filter-wrapper">
          {getLotSearchPatientsLoading || lotSearchPatientsExportLoading ?  <Loading /> : null}
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">NDC</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter NDC"
              value={ndc}
              onChange={onNDCChange}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter lot no"
              value={lot}
              onChange={onLotChange}
            />
          </div>
          <div className="filter-item-box clear-all-box">
            <Button
              type="button"
              variant="primary"
              onClick={getlotPatientsData}
            >
              View
            </Button>
            <button type="button" className="clear-filter-btn"  onClick={cleearAll}>
              Clear All
            </button>
          </div>
        </div>
        {lotPatientsData?.reportData && lotPatientsData?.reportData.length > 0 ? (
            <div className="report-export-box">
              <button type="button" className="report-export-btn" onClick={handleExport}>
                <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
                Export
              </button>
            </div>
          ):
          ( 
            ""
          )
        }
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4>{lotPatientsData?.titleReport ?? ""}</h4>
          </div>
           {
          lotPatientsData?.reportData && lotPatientsData?.reportData.length > 0 ?
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Patient ID</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>RXID</th>
                  <th>RX Name</th>
                  <th>NDC Code</th>
                  <th>LOT NO</th>
                  <th>EXP. DATE</th>
                </tr>
              </thead>
              <tbody>
                 {
                lotPatientsData?.reportData?.length > 0 && lotPatientsData?.reportData.map((pantientDetails:any)=>(
                <tr>
                  <td>{pantientDetails?.patientID}</td>
                  <td>{pantientDetails?.patientName}</td>
                  <td>{pantientDetails?.dob}</td>
                  <td>M</td>
                  <td>{pantientDetails?.shotDate}</td>
                  <td>{pantientDetails?.userName}</td>
                  <td>{pantientDetails?.rxSRXId}</td>
                  <td>{pantientDetails?.rxName}</td>
                  <td>{pantientDetails?.ndcCode}</td>
                  <td>{pantientDetails?.lotNo}</td>
                  <td>{pantientDetails?.expiryDate}</td>
                </tr>
                ))
              }
              </tbody>
            </Table>
          </div>
          :
           Object.keys(lotPatientsData).length === 0 ? (
              ""
            ) : (
              <span className="d-flex justify-content-center align-items-center mt-5">
                Data not found..!
              </span>
            )
        }
        </div>
      </div>
    </div>
  );
};

export default LotSearchPatients;
