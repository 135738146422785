import { useEffect, useState } from "react";
import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import DatePicker from "react-date-picker";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import moment from "moment";
import Select from "react-select";
import useLocations from "../../../Hooks/useLocations";
import Loading from "../../../components/LoadingPage/Loading";
import useItemTypes from "../../../Hooks/useItemTypes";
import useStocks from "../../../Hooks/useStocks";
import { Button } from "react-bootstrap";
import useReports from "../../../Hooks/useReports";
interface labelOption {
  value: string;
  label: string;
}
const InventoryStatusReport = () => {
  const [selectedStock, setSelectedStock] = useState<labelOption>({value: "",label: "All"});
  const [selectedType, setSelectedType] = useState<labelOption>({value: "2",label: "All"});
  const [selectedLoc, setSelectedLoc] = useState<labelOption>({value: "",label: "All"});
  const [date, setDate] = useState(moment(Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
  const [lot, setLot] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isIncludeZero, setIsIncludeZero] = useState(false);
  const defaultFilter = { searchText: "", active: true, sortColumn: "" ,sortDirection: ""};
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const [locationsList, setLocationsList] = useState<labelOption[]>([]);
  const [typesList, setTypesList] = useState<labelOption[]>([]);
  const [stocksList, setStocksList] = useState<labelOption[]>([]);
  const { getLocationsAPI, getLocationsLoading } = useLocations();
  const { getItemTypesAPI, getItemTypesLoading } = useItemTypes();
  const { getStocksAPI, getStocksLoading } = useStocks();
  const { inventoryStausExportAPI, inventoryStausExportLoading} = useReports();
  const [reportKey, setReportKey] = useState(0);

  const onSelectDate = (date: any) => {
    setDate(moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
  };

  const onLotChange = (e: any) => {
    setLot(e.target.value);
  };

  const getLocations = () => {
    getLocationsAPI(searchFieldVal)
      .then((res: any) => {
        const options = res?.map((option: any) => ({
          value: option.locationCode,
          label: option.locationName,
        }));
        setLocationsList([{value: "",label: "All"},...options]);
      })
      .catch();
  };

  const getTypes = () => {
    getItemTypesAPI(searchFieldVal).then((res: any) =>{
      const options = res?.map((option: any) => ({
          value: option.rxTypeCode,
          label: option.rxTypeName,
        }));
        setTypesList([{value: "2",label: "All"},...options]);
    }).catch();
  };

  const getStocks = () => {
    getStocksAPI(searchFieldVal)
      .then((res: any) => {
         const options = res?.map((option: any) => ({
          value: option.stockCode,
          label: option.stockName,
        }));
        setStocksList([{value: "",label: "All"},...options])
      })
      .catch();
  };

  const handleViewReport =()=>{
    setReportKey((prevKey) => prevKey + 1);
  }

  const handleExport = async ()=>{
    const response = await inventoryStausExportAPI({
      reportDate: date,
      locationId: selectedLoc?.value ? `${selectedLoc?.value}` : "",
      drxTypeId: selectedType?.value ? `${selectedType?.value}` : "2",
      stockId: selectedStock?.value ? `${selectedStock?.value}` : "",
      lotNo: lot,
      isActive: isActive,
      isZeroQty: isIncludeZero,
      isExport: "false",
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Inventory_Status_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }

  const cleearAll = () => {
    setDate(moment(Date()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    setLot("");
    setSelectedStock({value: "",label: "All"});
    setSelectedType({value: "2",label: "All"});
    setSelectedLoc({value: "",label: "All"});
    setIsIncludeZero(false);
    setIsActive(true);
    handleViewReport();
  };

   useEffect(() => {
    getLocations();
    getTypes();
    getStocks();
  }, [searchFieldVal]);

  return (
    <>
      {/* <div className="report-content-wrapper"> */}
      {/* <ReportHeader
        reportTitle="Inventory Status Report"
        reportDate="04 April 2024"
      /> */}
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          {getLocationsLoading || getItemTypesLoading || getStocksLoading || inventoryStausExportLoading  ?  <Loading /> : null}
          <div className="filter-item-box">
            <label className="form-label">Date</label>
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={date}
              onChange={(date: any) => {
                onSelectDate(date);
              }}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <Select 
              classNamePrefix="react-select"
              options={locationsList}
              value={locationsList?.find(
              (option: any) => selectedLoc?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedLoc(option);
              }}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <Select 
              classNamePrefix="react-select"
              options={typesList}
              value={typesList?.find(
              (option: any) => selectedType?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedType(option);
              }}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Stock</label>
            <Select 
              classNamePrefix="react-select"
              options={stocksList}
              value={stocksList?.find(
              (option: any) => selectedStock?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedStock(option);
              }}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Lot"
              value={lot}
              onChange={onLotChange}
            />
          </div>
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isIncludeZero}
                onChange={(e) => {
                  setIsIncludeZero(e.target.checked);
                }}
              />
              <label className="form-check-label">Include 0 Quantity</label>
            </div>
          </div>
          <div className="filter-item-box clear-all-box">
            <Button
              type="button"
              variant="primary"
              onClick={handleViewReport}
            >
              View
            </Button>
            <button
              type="button"
              className="clear-filter-btn"
              onClick={cleearAll}
            >
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn" onClick={handleExport}>
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />
            Export
          </button>
        </div>
      </div>
     <TelerikReporting
        key={reportKey}
        params={{
          reportDate: date,
          locationId: selectedLoc?.value ? `${selectedLoc?.value}` : "",
          drxTypeId: selectedType?.value ? `${selectedType?.value}` : "2",
          stockId: selectedStock?.value ? `${selectedStock?.value}` : "",
          lotNo: lot,
          isActive: isActive,
          isZeroQty: isIncludeZero,
          isExport: "false",
        }}
        reportName="InventoryStatusReport.trdp"
        reportEndPoint="reports/inventorystatus/get"
      />
    </>
    //</div>
  );
};

export default InventoryStatusReport;
