import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "./../commonBaseApiSlice";

export type UserProfileType = {
  firstName: string;
  lastName: string;
  photoUrl: string;
  fullName: string;
  phone: string | null;
  username: string;
  id: string;
  selectedUser: any;
  npiData: any;
};

export type UserCreate = {
  isActive: true;
  deleted: true;
  userName: string;
  password: string;
  title: string;
  fName: string;
  lName: string;
  mi: string;
  toalCountAsHCP: 0;
  toalCountAsUser: 0;
  forceChangePassword: true;
  notes: string;
  deaNumber: string;
  email: string;
  userValue: 0;
  clinicalRoleId: string;
  emrId: string;
  roleId: string;
  profilePicture?: null;
};
export type UserEdit = UserCreate & {
  id: string | null;
};

export const userApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (Data: UserCreate) => ({
        url: "/user/create",
        method: "POST",
        body: Data,
      }),
    }),
    editUser: builder.mutation({
      query: (Data: UserCreate) => ({
        url: "/user/edit",
        method: "PUT",
        body: Data,
      }),
    }),
    getUsers: builder.mutation({
      query: (data) => ({
        url: `/user/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getUserById: builder.mutation({
      query: (data) => ({
        url: `/user/getbyid?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getNpi: builder.mutation({
      query: (data) => ({
        url: `/user/npisearch?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getUserCreateDDVal: builder.mutation({
      query: () => ({
        url: `/user/renderuserpage`,
        method: "GET",
      }),
    }),
    setUserStatusActive: builder.mutation({
      query: (data) => ({
        url: `/user/activate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    setUserStatusDeActive: builder.mutation({
      query: (data) => ({
        url: `/user/deactivate?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    duplicateUser: builder.mutation({
      query: (data) => ({
        url: `/user/duplicate?${getFilters(data)}`,
        method: "POST",
      }),
    }),
    userResetPassword: builder.mutation({
      query: (data) => ({
        url: `/user/resetpasswordbyadmin?${getFilters(data)}`,
        method: "POST",
      }),
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: `/user/softdelete?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    uploadProfilePicture: builder.mutation({
      query: (data) => ({
        url: "/user/uploadprofilepicture",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUserByIdMutation,
  useCreateUserMutation,
  useGetUsersMutation,
  useGetNpiMutation,
  useGetUserCreateDDValMutation,
  useSetUserStatusActiveMutation,
  useSetUserStatusDeActiveMutation,
  useDuplicateUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useUserResetPasswordMutation,
  useUploadProfilePictureMutation
} = userApiSlice;
