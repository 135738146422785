import { getErrorMessage } from "../utils/commonUtils";
import {
  useCreateSettingsMutation,
  useDeleteSettingMutation,
  useEditSettingsMutation,
  useGetSettingsByIdMutation,
  useGetSettingsMutation,
  useUploadCompanyLogoMutation,
} from "../redux/settings/settingsApiSlice";
import { toast } from "react-toastify";

export default function useSettings() {
  const [getSettings, { isLoading: getSettingsLoading }] =
    useGetSettingsMutation();
  const [createSettings, { isLoading: createSettingsLoading }] =
    useCreateSettingsMutation();
  const [editSettings, { isLoading: editSettingsLoading }] =
    useEditSettingsMutation();
  const [deleteSetting, { isLoading: deleteSettingLoading }] =
    useDeleteSettingMutation();
  const [getSettingsById, { isLoading: getSettingsByIdLoading }] =
    useGetSettingsByIdMutation();
  const [uploadCompanyLogo,{isLoading: uploadCompanyLogoLoading}] = useUploadCompanyLogoMutation();

  const getSettingsAPI = async (data: any) => {
    try {
      const response: any = await getSettings(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getSettingsByIdAPI = async (data: any) => {
    try {
      const response: any = await getSettingsById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createSettingsAPI = async (data: any) => {
    try {
      const response: any = await createSettings(data).unwrap();
      toast.success("Success! Setting has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const editSettingsAPI = async (data: any) => {
    try {
      const response: any = await editSettings(data).unwrap();
      toast.success("Success! Setting has been Updated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const deleteSettingAPI = async (data: any) => {
    try {
      const response: any = await deleteSetting(data).unwrap();
      toast.success("Success! Setting has been Deleted.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const uploadCompanyLogoApi = async (data: any) => {
    try {
      const response = await uploadCompanyLogo(data).unwrap();
      return response;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  return {
    getSettingsAPI,
    getSettingsLoading,
    createSettingsLoading,
    createSettingsAPI,
    editSettingsAPI,
    editSettingsLoading,
    deleteSettingAPI,
    deleteSettingLoading,
    getSettingsByIdAPI,
    getSettingsByIdLoading,
    uploadCompanyLogoApi,
    uploadCompanyLogoLoading
  };
}
