import React, { useEffect, useState } from "react";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import cx from "classnames";
import "./RolePermissionTree.scss";

const initValue = {
  name: "",
  children: [
    {
      name: "Dashboard",
      children: [],
    },
  ],
};


const ArrowIcon = ({ isOpen, className }:any) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
  return <IoMdArrowDropright className={classes} />;
};

const CheckBoxIcon = ({ variant, ...rest }:any) => {
  switch (variant) {
    case "all":
      return <FaCheckSquare {...rest} />;
    case "none":
      return <FaSquare {...rest} />;
    case "some":
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};



const RolePermissionMenuTree =({menuList, setMenuPermissionList}:any)=> {
    const [finalMenuList, setFinalMenuList] = useState<any>(initValue);
    const [selectedIds, setSelectedIds] = useState<any>();
    const handleOnSelect = (props:any) => {
      console.log('Props',props.treeState);
      const selectedIdsArray = Array.from(props.treeState.selectedIds);
       setMenuPermissionList(selectedIdsArray);
    }
    useEffect(()=>{
        let preSelectIds = Object.keys(menuList).flatMap((module) =>
          menuList[module]
            .flatMap((permission: any) => {
              // Function to recursively extract checked ids from a permission
              const getCheckedIds = (permission: any) => {
                let ids = [];
                if (permission.isChecked) {
                  ids.push(permission.id); // Add the current id if isChecked is true
                }
                // Check for childMenus and recursively extract their ids
                if (permission.childMenus && permission.childMenus.length > 0) {
                  ids = ids.concat(
                    permission.childMenus.flatMap((childPermission: any) =>
                      getCheckedIds(childPermission) // Recursively process child menus
                    )
                  );
                }
                return ids;
              };

              // Process each permission and extract checked ids
              return getCheckedIds(permission);
            })
        );
        setSelectedIds(preSelectIds);

        const menuData = menuList?.Menu?.reduce((acc:any,menuItem:any)=>{
            acc.children.push({
                name: menuItem?.name,
                id: menuItem?.id,
                metadata: {route: menuItem?.route,isChecked: menuItem?.isChecked},
                children: menuItem?.childMenus?.map((childItem:any)=>{
                    return {
                        name: childItem?.name, 
                        children:childItem?.childMenus?.map((subChildItem:any)=>{
                            return {
                                name :subChildItem?.name,
                                children: subChildItem?.childMenus?.map((lastChild:any)=>{
                                    return {
                                        name: lastChild?.name,
                                        children: [],
                                        id:lastChild?.id,
                                        metadata: {route: lastChild?.route,isChecked: lastChild?.isChecked},
                                    }
                                }),
                                id:  subChildItem?.id,
                                metadata: {route: subChildItem?.route,isChecked: subChildItem?.isChecked},
                            }
                        }),
                        id:  childItem?.id,
                        metadata: {route: childItem?.route,isChecked: childItem?.isChecked},
                    }
                }),
            })
            return acc;
        },{name: "",children:[]});
        if(menuData){
            setFinalMenuList(menuData);
        }
    },[menuList]);
  return (
    <div>
      <div className="checkbox">
        <TreeView
          data={flattenTree(finalMenuList)}
          aria-label="Checkbox tree"
          multiSelect
          propagateSelect
          propagateSelectUpwards
          togglableSelect
          selectedIds={selectedIds}
          onSelect={(props:any) => {
            handleOnSelect(props);
          }}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            getNodeProps,
            level,
            handleSelect,
            handleExpand,
          }) => {
            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{ marginLeft: 40 * (level - 1) }}
              >
                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                 <CheckBoxIcon
                    className="checkbox-icon"
                    onClick={(e:any) => {
                        handleSelect(e);
                        e.stopPropagation();
                    }}
                    variant={
                        isHalfSelected ? "some" : isSelected ? "all" : "none"
                    }
                    />
                <span className="name">{element.name}</span>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}



export default RolePermissionMenuTree;