import { getErrorMessage } from "../utils/commonUtils";
import {
  UserCreate,
  UserEdit,
  useCreateUserMutation,
  useDeleteUserMutation,
  useDuplicateUserMutation,
  useEditUserMutation,
  useGetNpiMutation,
  useGetUserCreateDDValMutation,
  useGetUsersMutation,
  useSetUserStatusActiveMutation,
  useSetUserStatusDeActiveMutation,
  useGetUserByIdMutation,
  useUserResetPasswordMutation,
  useUploadProfilePictureMutation,
} from "./../redux/user/userApiSlice";
import { toast } from "react-toastify";

export type UserStatusUpdate = {
  id: string;
  updatedBy: string;
};
export default function useUser() {
  const [createUser, { isLoading }] = useCreateUserMutation();
  const [getUsers, { isLoading: getUsersListLoading }] = useGetUsersMutation();
  const [getUserById, { isLoading: getUserByIdLoading }] =
    useGetUserByIdMutation();
  const [getNpi, { isLoading: getNpiLoading }] = useGetNpiMutation();
  const [getUserCreateDDVal, { isLoading: getUserCreateDDValLoading }] =
    useGetUserCreateDDValMutation();
  const [setUserStatusActive, { isLoading: setUserStatusActiveLoading }] =
    useSetUserStatusActiveMutation();
  const [setUserStatusDeActive, { isLoading: setUserStatusDeActiveLoading }] =
    useSetUserStatusDeActiveMutation();
  const [duplicateUser, { isLoading: duplicateUserLoading }] =
    useDuplicateUserMutation();
  const [editUser, { isLoading: editUserLoading }] = useEditUserMutation();
  const [deleteUser, { isLoading: deleteUserLoading }] =
    useDeleteUserMutation();
  const [resetPassword, { isLoading: resetPasswordLoading }] =
    useUserResetPasswordMutation();
  const [uploadProfilePicture, {isLoading: uploadProfilePictureLoading}] = 
    useUploadProfilePictureMutation();

  const getUserByIdApi = async (data: any) => {
    try {
      const response = await getUserById(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const createUsers = async (data: UserCreate) => {
    try {
      const response = await createUser(data).unwrap();
      toast.success("Success! User profile has been Created.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const getUserList = async (data: any) => {
    try {
      const response = await getUsers(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getNpiData = async (data: any) => {
    try {
      const response = await getNpi(data).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const getUserCreateDDValues = async () => {
    try {
      const response = await getUserCreateDDVal({}).unwrap();
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const setUserActive = async (data: UserStatusUpdate) => {
    try {
      const response = await setUserStatusActive(data).unwrap();
      toast.success("User profile has been activated successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const setUserInActive = async (data: UserStatusUpdate) => {
    try {
      const response = await setUserStatusDeActive(data).unwrap();
      toast.success("User profile has been deactivated successfully");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const userDuplicate = async (data: UserStatusUpdate) => {
    try {
      const response = await duplicateUser(data).unwrap();
      toast.success("Success! User has been Duplicated.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const updateUsers = async (data: any) => {
    try {
      const response = await editUser(data).unwrap();
      toast.success("Success! User profile has been updated.");
      return response?.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };
  const deleteUserAPI = async (data: any) => {
    try {
      const response = await deleteUser(data).unwrap();
      toast.success("User deleted successfully.");
      return response.result;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const onResetPassword = async (data: any) => {
    try {
      const response = await resetPassword(data).unwrap();
      return response;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  const uploadProfilePictureApi = async (data: any) => {
    try {
      const response = await uploadProfilePicture(data).unwrap();
      return response;
    } catch (err: any) {
      const message = getErrorMessage(err);
      throw new Error(message);
    }
  };

  return {
    getUserByIdApi,
    getUserByIdLoading,
    createUsers,
    isLoading,
    getUserList,
    getUsersListLoading,
    getNpiData,
    getNpiLoading,
    getUserCreateDDValues,
    getUserCreateDDValLoading,
    setUserActive,
    setUserStatusActiveLoading,
    setUserInActive,
    setUserStatusDeActiveLoading,
    userDuplicate,
    duplicateUserLoading,
    updateUsers,
    editUserLoading,
    deleteUserAPI,
    deleteUserLoading,
    onResetPassword,
    resetPasswordLoading,
    uploadProfilePictureApi,
    uploadProfilePictureLoading
  };
}
