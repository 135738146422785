import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import useItemTypes from "../../../Hooks/useItemTypes";
import Select from "react-select";
import { Button } from "react-bootstrap";
import useReports from "../../../Hooks/useReports";
import Loading from "../../../components/LoadingPage/Loading";
import moment from "moment";
interface labelOption {
  value: string;
  label: string;
}
interface showLabelOption {
  value: number;
  label: string;
}
const showOption = [
  { value:0, label:"All"},
  { value:1, label:"Active"},
  { value:2, label:"Active & Recall"},
  { value:3, label:"Active & Expired"},
  { value:4, label:"Inactive"},
]
const MedicationsList = () => {
  const [selectedType, setSelectedType] = useState<labelOption>({value: "2",label: "All"});
  const [selectedShow, setSelectedShow] = useState<showLabelOption>({value: 0 ,label: "All"});
  const defaultFilter = { searchText: "", active: true, sortColumn: "" ,sortDirection: ""};
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const [typesList, setTypesList] = useState<labelOption[]>([]);
  const { getItemTypesAPI, getItemTypesLoading } = useItemTypes();
  const [reportKey, setReportKey] = useState(0);
  const { medicationListExportAPI, medicationListExportLoading} = useReports();
  
  const handleViewReport =()=>{
    setReportKey((prevKey) => prevKey + 1);
  }
  const getTypes = () => {
    getItemTypesAPI(searchFieldVal).then((res: any) =>{
      const options = res?.map((option: any) => ({
          value: option.rxTypeCode,
          label: option.rxTypeName,
        }));
        setTypesList([{value: "2",label: "All"},...options]);
    }).catch();
  };
  const handleMedicationListExport = async ()=>{
    const response = await medicationListExportAPI({
      rxTypeId: selectedType?.value ? `${selectedType?.value}` : "2",
      filter: selectedShow?.value ? `${selectedShow?.value}` : 0,
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Medication_List_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }

  const cleearAll = () => {
      
      setSelectedType({value: "2",label: "All"});
      setSelectedShow({value: 0 ,label: "All"});
      handleViewReport();
  };
   useEffect(() => {
      getTypes();
    }, [searchFieldVal]);
  return (
    <>
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
        { getItemTypesLoading || medicationListExportLoading ?  <Loading /> : null}
         <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <Select 
              classNamePrefix="react-select"
              options={typesList}
              value={typesList?.find(
              (option: any) => selectedType?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedType(option);
              }}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Show</label>
            <Select 
              classNamePrefix="react-select"
              options={showOption}
              value={showOption?.find(
              (option: any) => selectedShow.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedShow(option);
              }}
            />
          </div>
          <div className="filter-item-box clear-all-box">
            <Button
              type="button"
              variant="primary"
              onClick={handleViewReport}
            >
              View
            </Button>
            <button type="button" className="clear-filter-btn" onClick={cleearAll}>
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn" onClick={handleMedicationListExport}>
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} /> UOM
            Export
          </button>
        </div>
      </div>
      <TelerikReporting
        key={reportKey}
        params={{
          rxTypeId: selectedType?.value ? `${selectedType?.value}` : "2",
          filter: selectedShow?.value ? `${selectedShow?.value}` : 0,
        }}
        reportName="ListofMedicationsReport.trdp"
        reportEndPoint="reports/medicationslist/get"
      />
    </>
  );
};

export default MedicationsList;
