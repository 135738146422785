import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { Button, Table } from "react-bootstrap";
import { useState } from "react";
import useReports from "../../../Hooks/useReports";
import Loading from "../../../components/LoadingPage/Loading";
import { toast } from "react-toastify";
import moment from "moment";

const LotSearchItem = () => {
  const [ndc, setNdc] = useState("");
  const [lot, setLot] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isIncludeZero, setIsIncludeZero] = useState(false);
  const [lotItemData, setLotItemData] = useState<any>({});
  const { getLotSearchItemsAPI, getLotSearchItemsLoading, lotSearchItemExportAPI,lotSearchItemExportLoading } = useReports();
  const onLotChange = (e: any) => {
    setLot(e.target.value);
  };
  const onNDCChange = (e: any) => {
    setNdc(e.target.value);
  };
  
  const getlotItemData = ()=>{
      getLotSearchItemsAPI({
        ndcCode: ndc,
        lotNo: lot,
        isActive: isActive,
        isZeroQty: isIncludeZero
    }).then((res) => {
        if(res?.result.length <= 0 && res?.status === 400){
           toast.error(res?.message);
           return;
        }else{
          setLotItemData(res?.result[0]);
        }
    });
  }
  const cleearAll = () => {
      setLot("");
      setNdc("");
      setIsIncludeZero(false);
      setIsActive(true);
      setLotItemData({});
  };

  const handleExport = async ()=>{
    const response = await lotSearchItemExportAPI({
      ndcCode: ndc,
      lotNo: lot,
      isActive: isActive,
      isZeroQty: isIncludeZero
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Lot_Search_Item_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="List of Medications"
        reportDate={lotItemData?.subTitleReport ?? ""}
        srxImage={lotItemData?.srxImage ?? ""}
        companyImage={lotItemData?.clientImage ?? ""}
      />
      <div className="report-filter-wrapper">
         {getLotSearchItemsLoading || lotSearchItemExportLoading ?  <Loading /> : null}
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">NDC</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter NDC"
              value={ndc}
              onChange={onNDCChange}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter lot no"
              value={lot}
              onChange={onLotChange}
            />
          </div>
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" 
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" 
               checked={isIncludeZero}
                onChange={(e) => {
                  setIsIncludeZero(e.target.checked);
                }}
              />
              <label className="form-check-label">Include 0 Quantity</label>
            </div>
          </div>
          <div className="filter-item-box clear-all-box">
             <Button
              type="button"
              variant="primary"
              onClick={getlotItemData}
            >
              View
            </Button>
            <button type="button" className="clear-filter-btn"  onClick={cleearAll}>
              Clear All
            </button>
          </div>
        </div>
        {lotItemData?.reportData && lotItemData?.reportData.length > 0 ? (
            <div className="report-export-box">
              <button type="button" className="report-export-btn" onClick={handleExport}>
                <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
                Export
              </button>
            </div>
          ):
          ( 
            ""
          )
        }
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4>{lotItemData?.titleReport ?? ""}</h4>
          </div>
          {
          lotItemData?.reportData && lotItemData?.reportData.length > 0 ?
            <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>RX Name</th>
                  <th>MANUFACTURER</th>
                  <th>NDC</th>
                  <th>LOT NO</th>
                  <th>EXP. DATE</th>
                  <th>Group</th>
                  <th>RXID</th>
                  <th>Location</th>
                  <th>Qty</th>
                  <th>UOM</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {
                lotItemData?.reportData?.length > 0 && lotItemData?.reportData.map((itemDetails:any)=>(
                  <tr>
                  <td>{itemDetails?.rxName}</td>
                  <td>{itemDetails?.manufacturer}</td>
                  <td>{itemDetails?.ndcCode}</td>
                  <td>{itemDetails?.lotNo}</td>
                  <td>{itemDetails?.expiryDate}</td>
                  <td>{itemDetails?.rxGroup}</td>
                  <td>{itemDetails?.rxSRXId}</td>
                  <td>{itemDetails?.location}</td>
                  <td>{itemDetails?.quantity}</td>
                  <td>{itemDetails?.unitName}</td>
                  <td>{itemDetails?.status}</td>
                </tr>
                ))
              }
              </tbody>
            </Table>
          </div>
          :
           Object.keys(lotItemData).length === 0 ? (
              ""
            ) : (
              <span className="d-flex justify-content-center align-items-center mt-5">
                Data not found..!
              </span>
            )
        }
        </div>
      </div>
    </div>
  );
};

export default LotSearchItem;
