import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import exporticon from "../../../assets/images/export-icon.svg";
import useLocations from "../../../Hooks/useLocations";
import useItemTypes from "../../../Hooks/useItemTypes";
import useStocks from "../../../Hooks/useStocks";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import { Button } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import useUser from "../../../Hooks/useUser";
import Loading from "../../../components/LoadingPage/Loading";
import moment from "moment";
import useReports from "../../../Hooks/useReports";
interface labelOption {
  value: string;
  label: string;
}
const InventoryReceivingReport = () => {
  const [selectedStock, setSelectedStock] = useState({value: "",label: "All"});
   const [selectedType, setSelectedType] = useState({value: "2",label: "All"});
   const [selectedLoc, setSelectedLoc] = useState({value: "",label: "All"});
   const [selectedUser, setSelectedUser] = useState({value: "",label: "All"});
   const [locationsList, setLocationsList] = useState<labelOption[]>([]);
   const [typesList, setTypesList] = useState<labelOption[]>([]);
   const [stocksList, setStocksList] = useState<labelOption[]>([]);
   const [usersList, setUsersList] = useState<labelOption[]>([]);
   const { getLocationsAPI, getLocationsLoading } = useLocations();
   const { getItemTypesAPI, getItemTypesLoading } = useItemTypes();
   const { getStocksAPI, getStocksLoading } = useStocks();
   const { getUserList, getUsersListLoading } = useUser();
   const defaultFilter = { searchText: "", active: true, sortColumn: "" ,sortDirection: ""};
   const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const [sddate, setSdDate] = useState("");
  const [eddate, setEdDate] = useState("");
  const [reportKey, setReportKey] = useState(0);
  const { inventoryReceiveExportAPI, inventoryRecevieDetailExportLoading} = useReports();

  const onSelectDate = (date: any, type: string) => {
    if (type === "sd") {
      setSdDate(moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    } else {
      setEdDate(moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    }
  };
  const getLocations = () => {
      getLocationsAPI(searchFieldVal)
        .then((res: any) => {
          const options = res?.map((option: any) => ({
            value: option.locationCode,
            label: option.locationName,
          }));
          setLocationsList([{value: "",label: "All"},...options]);
        })
        .catch();
    };
  
    const getTypes = () => {
      getItemTypesAPI(searchFieldVal).then((res: any) =>{
        const options = res?.map((option: any) => ({
            value: option.rxTypeCode,
            label: option.rxTypeName,
          }));
          setTypesList([{value: "2",label: "All"},...options]);
      }).catch();
    };
  
    const getStocks = () => {
      getStocksAPI(searchFieldVal)
        .then((res: any) => {
           const options = res?.map((option: any) => ({
            value: option.stockCode,
            label: option.stockName,
          }));
          setStocksList([{value: "",label: "All"},...options])
        })
        .catch();
    };
  
    const fetchUserList = () => {
      getUserList(searchFieldVal)
      .then((res: any) => {
        if (res) {
           const options = res?.map((option: any) => ({
            value: option.id,
            label: `${option.lName}, ${option.fName} (${option.userName})`,
          }));
          setUsersList([{value: "",label: "All"},...options]);
        }
      });
    };
    const handleViewReport =()=>{
      setReportKey((prevKey) => prevKey + 1);
    }
    
    const handleInventoryReceivingExport = async ()=>{
    const response = await inventoryReceiveExportAPI({
      startDate: sddate,
      endDate: eddate,
      rxTypeId: selectedType?.value ? `${selectedType?.value}` : "2",
      stockId: selectedStock?.value ? `${selectedStock?.value}` : "",
      locationId: selectedLoc?.value ? `${selectedLoc?.value}` : "",
      userId: selectedUser?.value ? `${selectedUser?.value}` : "",
      isExport: true,
    });
    const url = URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${localStorage.getItem("profileClientId")?.toUpperCase() ?? ""}_Inventory_Receiving_Details_Report_${moment().format('YYYYMMDD_HHmmss')}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }


    const cleearAll = () => {
      setSdDate("");
      setEdDate("");
      setSelectedStock({value: "",label: "All"});
      setSelectedType({value: "2",label: "All"});
      setSelectedLoc({value: "",label: "All"});
      setSelectedUser({value: "",label: "All"});
      handleViewReport();
    };
  
    useEffect(() => {
      getLocations();
      getTypes();
      getStocks();
      fetchUserList();
    }, [searchFieldVal]);
  return (
    <>
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          {getLocationsLoading || getItemTypesLoading || getStocksLoading || getUsersListLoading || inventoryRecevieDetailExportLoading   ?  <Loading /> : null}
          <div className="filter-item-box">
            <label className="form-label">Start Date</label>
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={sddate}
              onChange={(date: any) => {
                onSelectDate(date, "sd");
              }}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">End Date</label>
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={eddate}
              onChange={(date: any) => {
                onSelectDate(date, "ed");
              }}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <Select 
              classNamePrefix="react-select"
              options={locationsList}
              value={locationsList?.find(
              (option: any) => selectedLoc?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedLoc(option);
              }}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <Select 
              classNamePrefix="react-select"
              options={typesList}
              value={typesList?.find(
              (option: any) => selectedType?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedType(option);
              }}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Stock</label>
            <Select 
              classNamePrefix="react-select"
              options={stocksList}
              value={stocksList?.find(
              (option: any) => selectedStock?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedStock(option);
              }}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">User</label>
            <Select 
              classNamePrefix="react-select"
              options={usersList}
              value={usersList?.find(
              (option: any) => selectedUser?.value=== option.value
              )}
              onChange={(option: any) => {
              setSelectedUser(option);
              }}
            />
          </div>
          <div className="filter-item-box clear-all-box">
            <Button
              type="button"
              variant="primary"
              onClick={handleViewReport}
            >
              View
            </Button>
            <button
              type="button"
              className="clear-filter-btn"
              onClick={cleearAll}
            >
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn" onClick={handleInventoryReceivingExport}>
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />
            Export
          </button>
        </div>
      </div>
       {reportKey > 0 ? 
          <TelerikReporting
            key={reportKey}
            params={{
              startDate: sddate,
              endDate: eddate,
              rxTypeId: selectedType?.value ? `${selectedType?.value}` : "2",
              stockId: selectedStock?.value ? `${selectedStock?.value}` : "",
              locationId: selectedLoc?.value ? `${selectedLoc?.value}` : "",
              userId: selectedUser?.value ? `${selectedUser?.value}` : "",
              isExport: "false",
            }}
            reportName="ReceivingDetailsReport.trdp"
            reportEndPoint="reports/inventoryreceivingdetails/get"
          /> 
        :
          ''
      }
       
    </>
  );
};

export default InventoryReceivingReport;
