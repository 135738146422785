import { toast } from "react-toastify";
import { menuItems, IMenuItem } from "./../global/Sidebar/MenuItems";
export const dateFormatter = (value: string) => {
  const timestamp = Date.parse(value);
  if (value && !isNaN(timestamp)) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month starts from 0, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  } else {
    return null;
  }
};
export function formatDateForAthena(value: string) {
  const timestamp = Date.parse(value);
  if (value && !isNaN(timestamp)) {
    const date = new Date(value);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${month}/${day}/${year} ${hours}:${minutes}`;
  } else {
    return null;
  }
}
const compareRoutePaths = (path2: string, path1: string) => {
  // Split the paths into segments
  const segments1 = path1.split("/");
  const segments2 = path2.split("/");
  let pathCount = 0;
  // Check if both paths have the same number of segments
  if (segments1.length !== segments2.length) {
    return {};
  }

  // Compare each segment
  for (let i = 0; i < segments1.length; i++) {
    if (segments2[i].startsWith(":")) {
      // Skip segments that are route parameters (e.g., :id)
      pathCount = i;
      continue;
    } else if (segments1[i] !== segments2[i]) {
      return {};
    }
  }

  // All non-parameter segments matched
  return { isValid: true, id: segments1[pathCount] };
};

export const getSideBarActiveDetails = (pathname: string) => {
  let menuItemDetails;
  let subMenuDetails;
  let pageDetails = null;
  menuItems.forEach((menuItem: IMenuItem) => {
    menuItem.subMenus?.find((item) => {
      if (item.path === pathname) {
        menuItemDetails = menuItem;
        subMenuDetails = item;
        return item;
      } else {
        item.pages?.find((page) => {
          const { isValid, id } = compareRoutePaths(page.path, pathname);

          if (isValid) {
            const pageWithId = {
              name: page.name,
              path: page.path.replace(":id", id),
            };
            menuItemDetails = menuItem;
            subMenuDetails = item;
            pageDetails = pageWithId;
            return item;
          }
          return null;
        });
      }
      return null;
    });
  });
  return { menuItemDetails, subMenuDetails, pageDetails };
};
export const formatDate = (date: string) => {
  try {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-GB");
  } catch {
    return date;
  }
};

export const getErrorMessage = (error: any) => {
  if (error?.data.result?.msg && error?.data?.result?.status === "F") {
    const message = error?.data?.result?.msg;
    toast.error(message);
    return message;
  } else if (error?.data?.errors && typeof error?.data?.errors === "object") {
    for (const [field, messages] of Object.entries(error.data.errors)) {
      const messageArray: string[] = messages as string[];
      messageArray.forEach((message, index) => {
        toast.error(`${field}: ${message}`);
      });
    }
    return error.data.errors;
  } else {
    const message =
      error?.data?.message || error?.data?.title  || error?.data?.result|| "something went wrong";
    toast.error(message);
    return message;
  }
};
export const getFilters = (params: any) => {
  return Object.keys(params)
    .filter(
      (key) =>
        params[key] !== "" && params[key] !== null && params[key] !== undefined,
    )
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key]),
    )
    .join("&");
};
export const toggleClass = () => {
  document.body.classList.toggle("customeModalOpen");
};
export const trimObjectValues = (obj: any) => {
  return Object.keys(obj).reduce((acc: any, key) => {
    const value = obj[key];
    acc[key] = typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    return acc;
  }, {});
};
export const checkTokenExpiration = (exp: number | null, time = 0) => {
  const currentTime = Date.now() / 1000 - time;
  return exp && exp < currentTime;
};
export const checkPermission = (module: string, identifier: string) => {
  const permissions = JSON.parse(localStorage.getItem("permissions") ?? "[]");
  const isPermission = permissions.find(
    (x: { module: string; identifier: string }) =>
      x.module === module && x.identifier === identifier,
  );
  return isPermission ? true : false;
};

export const currencyFormatter = ( amount: number ) => {
  // Format the number to US Dollar currency
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
  return formattedAmount;
};

export const MAX_IMAGE_FILE_SIZE = 500 * 1024; // 5KB
export const ALLOWED_IMAGE_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];