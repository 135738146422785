import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import "./productmasterlisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import duplicateicon from "../../../assets/images/duplicate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import exporticon from "../../../assets/images/export-icon.svg";
import importicon from "../../../assets/images/import-icon.svg";
import CustomModal from "../../../components/customModal/CustomModal";
import uploadfileicon from "../../../assets/images/upload-file-icon.svg";
import { ReactSVG } from "react-svg";
import useProducts from "../../../Hooks/useProduct";
import Loading from "../../../components/LoadingPage/Loading";
import {
  Product,
  setSelectedProduct,
} from "../../../redux/product/productSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DuplicateModel } from "./popup/duplicateModel";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { toast } from "react-toastify";
import React from "react";
const ProductMasterListing = () => {
  const [showImportFileModal, setShowImportFileModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const defaultFilter = { searchText: "" };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const {
    getProductAPI,
    deleteProductAPI,
    duplicateProductAPI,
    getProductLoading,
    deleteProductLoading,
    setProductStatusActiveLoading,
    setProductStatusDeActiveLoading,
    duplicateProductLoading,
    onExportProductMasterList,
    exportProductMasterLoading,
    onImportProductMasterFile,
    importProductMasterLoading
  } = useProducts();
  const [showModal, setShowModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [productList, setProductsList] = useState<Product[]>([]);
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const deleteProduct = async () => {
    await deleteProductAPI({
      id: currentProduct?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getProducts();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };

  const getProducts = () => {
    getProductAPI({ ...searchFieldVal, includeDeleted: false })
      .then((res: Product[]) => setProductsList(res))
      .catch();
  };
  const editProduct = (Product: Product) => {
    dispatch(setSelectedProduct(Product));
    navigate("/edit-product-master");
  };
  const navigateToView = (Product: Product) => {
    dispatch(setSelectedProduct(Product));
    navigate("/view-product-master");
  };
  const duplicateProduct = async (barcode: string) => {
    await duplicateProductAPI({
      barcode,
      id: currentProduct?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleDuplicateModal();
    getProducts();
  };
  useEffect(() => {
    getProducts();
  }, [searchFieldVal]);

  const toggleImportXlsxModal = () => {
    setSelectedFile(null);
    toggleClass();
    setShowImportFileModal(!showImportFileModal);
  };

  const onExportClick = async () => {
    const response = await onExportProductMasterList();
    const url = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Product_master.xlsx");
    document.body.appendChild(link);
    link.click();
  };

  const selectFile = (event: any) => {
    const file = event.target.files[0];

    // Check if file is selected
    if (!file) {
      toast.error("No file selected");
      return;
    }

    // Check file type (must be .xlsx)
    const fileType = file.type;
    if (
      fileType !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Please upload a valid .xlsx file");
      return;
    }

    // Check file size (must be <= 5MB)
    const fileSizeMB = file.size / (1024 * 1024); // convert to MB
    if (fileSizeMB > 5) {
      toast.error("File size must not exceed 5MB");
      return;
    }
    setSelectedFile(file);
  };

  const handleFileImport = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile || "");
    const response = await onImportProductMasterFile(formData);
    if (response) {
      toggleImportXlsxModal();
      getProducts();
    }
  };

  return (
    <div className="product-master-listing-wrapper">
      {getProductLoading ||
      deleteProductLoading ||
      setProductStatusActiveLoading ||
      setProductStatusDeActiveLoading ||
      exportProductMasterLoading ||
      importProductMasterLoading ||
      duplicateProductLoading ? (
        <Loading />
      ) : null}
      <div className="listing-title-box">
        <div className="listing-title-info">
          <h2>Product Master</h2>
          <p>
            Displaying {productList.length} of {productList.length} Results
          </p>
        </div>
        <div
          className="listing-searchbar-wrapper"
          style={{ justifyContent: "space-evenly" }}
        >
          <div
            className="listing-searchbar-item-wrapper"
            style={{ flex: "none", display: "contents" }}
          >
            <div className="listing-searchbar-item-box">
              <input
                type="text"
                min={0}
                className="form-control"
                placeholder="Search"
                onChange={(e: any) => {
                  setSearchFieldVal({
                    ...searchFieldVal,
                    searchText: e.target.value,
                  });
                }}
                value={searchFieldVal?.searchText ?? ""}
              />
            </div>
            <Button
              type="button"
              variant="outline-secondary"
              className="btn-search"
              onClick={() => {
                setSearchFieldVal({ ...defaultFilter });
              }}
            >
              Clear
            </Button>
          </div>
        </div>
        <div className="listing-action-box">
          <div className="import-export-box-wrapper">
            <span
              className="import-export-btn import-btn"
              onClick={toggleImportXlsxModal}
            >
              <ReactSVG className="svg-box" wrapper="span" src={importicon} />{" "}
              Import
            </span>
            <span
              className="import-export-btn export-btn"
              onClick={onExportClick}
            >
              <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
              Export
            </span>
          </div>
          <div className="action-box">
            <Button variant="primary" href="/add-product-master">
              <i className="bi bi-plus"></i>
              Add New
            </Button>
          </div>
        </div>
      </div>
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Product Code / NDC</th>
              <th>BARCODE</th>
              <th>PRODUCT NAME</th>
              <th>Category / RXGroup</th>
              <th>MANUFACTURER</th>
              <th>Model / CVX</th>
              <th>ISMED</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productList?.map((product: Product) => (
              <tr
                key={product.id}
                onDoubleClick={() => {
                  navigateToView(product);
                }}
              >
                <td title={product.productCode}>{product.productCode}</td>
                <td title={product.barcode}>{product.barcode}</td>
                <td title={product.productName}>{product.productName}</td>
                <td title={product.category}>{product.category}</td>
                <td title={product.manufacturer}>{product.manufacturer}</td>
                <td title={product.model}>{product.model}</td>
                <td>
                  <span
                    className={`status-circle ${product.isMedicine ? "active" : "inactive"}`}
                  >
                    {product.isMedicine ? "Yes" : "No"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(product)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => editProduct(product)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentProduct(product);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentProduct(product);
                          toggleDuplicateModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={duplicateicon}
                        />
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteProduct}
        recordName={currentProduct?.productName || ""}
        modelName={`product`}
      />
      <DuplicateModel
        showModal={showDuplicateModal}
        toggleDuplicateModal={toggleDuplicateModal}
        duplicateProduct={duplicateProduct}
        currentProduct={currentProduct}
      />
      {showImportFileModal && (
        <CustomModal
          id="upload-avatar-modal"
          title="Import"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="img-upload-box">
                  <Form.Control
                    type="file"
                    id="avatar-upload-input"
                    accept=".xlsx"
                    onChange={selectFile}
                  />
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={uploadfileicon}
                  />
                  <p>Drag and Drop your file here or Click to Upload</p>
                </div>
                <div className="file-type-hint">
                  <span>File Type: .xlsx</span>
                  <span>Maximum Size: 5 MB</span>
                </div>
                {selectedFile?.name && (
                  <div className="file-type-hint">
                    <span>File Name: {selectedFile?.name}</span>
                  </div>
                )}
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleImportXlsxModal}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleFileImport}
                  disabled={!selectedFile}
                >
                  Import
                </Button>
              </div>
            </>
          }
          onClose={toggleImportXlsxModal}
        />
      )}
    </div>
  );
};

export default ProductMasterListing;
